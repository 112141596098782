<template>
  <div>
    <v-row>
      <v-col cols="4">
        <v-btn color="primary" @click="$router.push('/ad/channels/create')">Создать новый канал</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="channelsLoaded">
      <v-col cols="12">
        <v-card>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th>ID</th>
                <th>Название</th>
                <th>Контакты</th>
                <th>Размещений</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(channel, i) in channels" :key="i">
                <td>{{channel.id}}</td>
                <td><router-link :to="{ path: '/ad/channels/'+channel.id}">{{channel.name}}</router-link> <router-link :to="{ path: '/ad/channels/'+channel.id}" target="_blank"><v-icon small>{{mdiOpenInNew}}</v-icon></router-link></td>
                <td>{{channel.admin_tg_id}}</td>
                <td>{{channel.publications.length || 0}}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </div>

</template>

<script>
import {mdiOpenInNew} from '@mdi/js'
import httpClient from '../../services/http.service'

export default {
  name: 'AdChannels',
  data(){
    return{
      mdiOpenInNew,
      channels: [],
      channelsLoaded: false,
    }
  },
  mounted() {
    httpClient.get('/ad-channels?expand=publications').then(({data})=>{
      this.channels = data
      this.channelsLoaded = true
    })
  },
  methods:{
    go(id){
      this.$router.push(`/ad/channels/${id}`)
    },
  },
}
</script>

<style scoped>

</style>
